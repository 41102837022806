body {
    background-image: url('../assets/BG.png');
    background-repeat: no-repeat;
    background-size: cover;
}

#root {
    image-rendering: pixelated;
}

.center {
    margin: auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.4);
}

.hidden {
    display: none;
}

.board {
    width: 570px;
    padding-top: 68.5px;
    margin: 20px auto 0 auto;
    background-image: url('../assets/Board.png');
    background-size: 150%;
    background-position: 50% 50%;
    height: 635px;
}

.board-row {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: -24.3px auto;
}

.hexCell {
    margin-left: 36px;
    margin-right: 36px;
}

.hexCell.first {
    margin-left: 33px;
}
.hexCell.last {
    margin-right: 33px;
}
.hexCell.active {
    margin:0;
}

.hexTop {
    width: 0;
    border-right: 30px solid rgb(141, 115, 59);
    border-top: 52px solid transparent;
    border-bottom: 52px solid transparent;
    display: inline-block;
}

.hexBottom {
    width: 0;
    border-left: 30px solid rgb(141, 115, 59);;
    border-top: 52px solid transparent;
    border-bottom: 52px solid transparent;
    display: inline-block;
}

.hexCenter {
    width: 60px;
    height: 104px;
    background-color: rgb(141, 115, 59);;
    display: inline-block;
}

.hex {
    width: 120px;
    height: 104px;
    display: inline-block;
}

.hex img {
    height: 100%;
}

.handCell {
    width: 90px;
    height: 78px;
    display: inline-block;
}

.handCell img {
    width: 100%;
}

.topImage {
    width: 50% !important;
    position: relative;
    top: -50%;
    left: 25%;
    float: left;
}

.roll {
    width:100%;
    color:#3c8ddf;
    border-color:#3c8ddf;
    border-radius:40px;
    padding: 0px;
    border-width:1px;
    font-weight:600;
    box-shadow:0px rgb(0 0 0 / 12%);
    background-color:transparent;
}

.selected {
    border: 5px solid black;
}